export default [
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'Recessed',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCQMFV51TNN69SFP84AG6EX4',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCQMG0CE2RS4DKV8WH2MNYKE',
        ],
      },
      {
        'id': 'B',
        'value': 'Lumenwerx',
      },
      {
        'id': 'AO',
        'value': 'VIA 2 PRISM RECESSED DIRECT',
      },
      {
        'id': 'G',
        'values': [
          'https://lumenwerx.com/en/product-line/via-2-prism/',
        ],
      },
      {
        'id': 'AW',
        'value': '2\' - wide linear prism recessed',
      },
      {
        'id': 'F',
        'value': 'Equipped with all of the features that have made Via among our most successful product lines, the Via 2 Prism pushes \
        the technological envelope even further with its\nDiamond Prism OpticTM (DPOTM),\
         a sophisticated louver engineered to deliver soft, glare-free illumination that wraps around subjects casting gradients of light rather than\ndramatic sharp-edged shadows.',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://lumenwerx.com/wp-content/files/VIA2-PRISM-RECESSED-SPEC.pdf',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED',
        ],
      },
      {
        'id': 'K',
        'value': '2.6W, 3.8W, 5.9W, 8.2W, 10W',
      },
      {
        'id': 'AD',
        'values': [
          '2700K',
          '3000K',
          '3500K',
          '4000K',
        ],
      },
      {
        'id': 'M',
        'values': [
          '50000hrs',
        ],
      },
      {
        'id': 'L',
        'value': '350 LM/FT, 500 LM/FT, 750 LM/FT, 1000 LM/FT, 1200 LM/FT',
      },
      {
        'id': 'N',
        'values': [
          '1% Level',
          'Dimmable',
        ],
      },
      {
        'id': 'O',
        'values': [
          '0-10V',
          'DALI',
        ],
      },
      {
        'id': 'P',
        'values': [
          '120 volts',
          '277 volts',
          '347 volts',
        ],
      },
      {
        'id': 'AE',
        'value': '80 CRI, 90 CRI, 95 CRI',
      },
      {
        'id': 'AA',
        'values': [
          '01HCQMN7G1YFMGHP027R2VABNG',
        ],
      },
      {
        'id': 'AB',
        'values': [
          'https://lumenwerx.com/wp-content/files/VIA2-PRISM-RECESSED-SPEC.pdf',
        ],
      },
      {
        'id': 'U',
        'value': 'Damp location',
      },
      {
        'id': 'V',
        'values': [
          'ETL',
          'CSA',
        ],
      },
    ],
  },
  {
    collection: [
      {
        id: 'C',
        values: ['Flexible Linear'],
      },
      {
        id: 'D',
        values: ['01HCAE4K4C7FS8EPM3H8RDCHS7'],
      },
      {
        id: 'E',
        values: ['01HCAE4Z05QT7RA66EDJTZGDG5'],
      },
      {
        id: 'B',
        value: 'Lumini',
      },
      {
        id: 'AO',
        value: 'Kurba Max Vertical',
      },
      {
        id: 'G',
        values: ['https://www.luminii.com/product/kurba-max-vertical/'],
      },
      {
        id: 'AW',
        value: 'Kurba Max Vertical',
      },
      {
        id: 'F',
        value:
          'Kurba Max Vertical is a direct view, high output, energy efficient and flexible LED strip suitable for wet locations capable of vertical/top bends up to 2.5″ radius.',
      },
      {
        id: 'S',
        values: ['Exterior'],
      },
      {
        id: 'Z',
        values: [
          'https://www.luminii.com/wp-content/uploads/z_special_catalog_products/products/kurba-max-vertical/specsheets/KBX-SF-V-specification.pdf',
        ],
      },
      {
        id: 'J',
        values: ['Integrated LED'],
      },
      {
        id: 'K',
        value: '5.4 W/ft',
      },
      {
        id: 'AD',
        values: ['2200', '2400', '2700K', '3000K', '3500K', '4000K'],
      },
      {
        id: 'M',
        values: ['50000hrs'],
      },
      {
        id: 'L',
        value: 'Light Output at 3000K is 458 lm/ft',
      },
      {
        id: 'P',
        values: ['120 volts', '277 volts'],
      },
      {
        id: 'AB',
        values: [
          'https://www.luminii.com/wp-content/uploads/z_special_catalog_products/products/kurba-max-vertical/specsheets/KBX-SF-V-specification.pdf',
        ],
      },
      {
        id: 'U',
        value: 'IP67',
      },
      {
        id: 'V',
        values: ['c.UL.us listed'],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C', 'values': ['Pendant', 'Custom'],
      },
      {
        'id': 'D', 'values': ['01HCAHXWM8RRDMA78SEYF0EWD7'],
      },
      {
        'id': 'E', 'values': ['01HCAHY35JVQHV9TZEXDANRJA0'],
      },
      {
        'id': 'B', 'value': 'Axis',
      },
      {
        'id': 'AO', 'value': 'Sketch Pendant Swirl Circle',
      },
      {
        'id': 'G', 'values': ['https://www.axislighting.com/families/sketch'],
      },
      {
        'id': 'AW', 'value': 'Sketch expresses interior architectural design, and integrates elegantly with it.',
      },
      {
        'id': 'F', 'value': 'Using Sketch®, lighting can now flow with the same grace as lines emanate from a pencil.i',
      },
      {
        'id': 'S', 'values': ['Interior'],
      },
      {
        'id': 'Z', 'values': ['https://www.axislighting.com/application/files/7516/6932/4852/SK_SW_CREATIVE_COLLECTION.SPEC.pdf'],
      },
      {
        'id': 'J', 'values': ['Integrated LED'],
      },
      {
        'id': 'AD', 'values': ['2700K', '3000K', '3500K', '4000K'],
      },
      {
        'id': 'L', 'value': '400 lm/ft, 700 lm/ft',
      },
      {
        'id': 'N', 'values': ['Dimmable', '1% Level'],
      },
      {
        'id': 'O', 'values': ['0-10V'],
      },
      {
        'id': 'P', 'values': ['120 volts', '277 volts'],
      },
      {
        'id': 'AE', 'value': '80 CRI, 90 CRI',
      },
      {
        'id': 'H', 'value': 'Aluminum paint, white, black, custom',
      },
      {
        'id': 'AB', 'values': ['https://www.axislighting.com/application/files/7516/6932/4852/SK_SW_CREATIVE_COLLECTION.SPEC.pdf'],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C', 'values': ['Recessed', 'Linear Profile'],
      },
      {
        'id': 'D', 'values': ['01HCAJTK0GRS9K4FBRNSRPD50S', '01HCAJTK3ZA2QXPHEBPXWKN35S'],
      },
      {
        'id': 'E', 'values': ['01HCAJTRVZ8ZTR8S8DK7C0ZNAT'],
      },
      {
        'id': 'B', 'value': 'Lumini',
      },
      {
        'id': 'AO', 'value': 'Stiletto',
      },
      {
        'id': 'G', 'values': ['https://www.luminii.com/product/stiletto/'],
      },
      {
        'id': 'AW', 'value': 'Recessed trimless linear profile + black lens',
      },
      {
        'id': 'F', 'value': 'The Stiletto is a trimless-recessed linear profile for drywall ceilings. ',
      },
      {
        'id': 'S', 'values': ['Interior'],
      },
      {
        'id': 'Z', 'values': ['https://www.luminii.com/wp-content/uploads/z_special_catalog_products/products/stiletto/specsheets/STILETTO_specsheet.pdf'],
      },
      {
        'id': 'J', 'values': ['Integrated LED'],
      },
      {
        'id': 'K', 'value': '25W, 40W, 60W, 96W',
      },
      {
        'id': 'AD', 'values': ['2200', '2500', '2700K', '3000K', '3500K', '4000K', '5000'],
      },
      {
        'id': 'L', 'value': '110 LUMENS/FT, 207 LUMENS/FT, 329 LUMENS/FT, 433 LUMENS/FT',
      },
      {
        'id': 'P', 'values': ['120 volts', '277 volts', '347 volts'],
      },
      {
        'id': 'AE', 'value': '90 CRI',
      },
      {
        'id': 'AB', 'values': ['https://www.luminii.com/wp-content/uploads/z_special_catalog_products/products/stiletto/install-guides/STILETTO_installation_guide.pdf'],
      },
      {
        'id': 'V', 'values': ['c.UL.us listed'],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'Troffer',
          'Recessed',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCCWJ6Q3T6AQ517JMSTQ9M88',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCCWK9BRTHG7CQFTD2TP2K7W',
        ],
      },
      {
        'id': 'B',
        'value': 'Visioneering',
      },
      {
        'id': 'AO',
        'value': 'OTLO (Outline Series)',
      },
      {
        'id': 'G',
        'values': [
          'https://www.viscor.com/en/products/otlo',
        ],
      },
      {
        'id': 'AW',
        'value': 'LED Troffer Dish Outline Type O',
      },
      {
        'id': 'F',
        'value': 'The OutlineTM LED luminaire provides dramatic visual impact to any modern architectural space.',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://www.viscor.com/en/docs/OTLO_Spec_Sheet_v2.pdf',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED',
        ],
      },
      {
        'id': 'BC',
        'value': '',
      },
      {
        'id': 'K',
        'value': '18.3W, 37.6W, 58.8W',
      },
      {
        'id': 'AD',
        'values': [
          '3000K',
          '3500K',
          '4000K',
          '5000',
          '2700-6500 Tunable White',
        ],
      },
      {
        'id': 'M',
        'values': [
          '50000hrs',
        ],
      },
      {
        'id': 'L',
        'value': '2059lm, 4067lm, 6077lm',
      },
      {
        'id': 'AJ',
        'value': '',
      },
      {
        'id': 'N',
        'values': [
          'Dimmable',
          '1% Level',
        ],
      },
      {
        'id': 'O',
        'values': [
          '0-10V',
        ],
      },
      {
        'id': 'P',
        'values': [
          '120 volts',
          '277 volts',
          '347 volts',
        ],
      },
      {
        'id': 'AE',
        'value': '80+, 90+',
      },
      {
        'id': 'H',
        'value': 'White polyester powder painted surfaces.',
      },
      {
        'id': 'AA',
        'values': [
          '01HCCX1SGHX9SBAW03X16EMPZ3',
          '01HCCX1SG41YQDVT9B82YFDMFN',
          '01HCCX1SG8FW5183WFRH4YVHFQ',
        ],
      },
      {
        'id': 'AB',
        'values': [
          'https://www.viscor.com/en/docs/IIS00075-LED_RECESSED_INSTALLATION-ENG.PDF',
        ],
      },
      {
        'id': 'U',
        'value': 'Damp location',
      },
      {
        'id': 'V',
        'values': [
          'c.UL.us listed',
        ],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'Surface',
          'Pendant',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCCXGFA6QC0FSVG89ZQK4YM2',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCCXGM7E3KBVRB4A3E4MNQV2',
        ],
      },
      {
        'id': 'B',
        'value': 'Liteline',
      },
      {
        'id': 'AO',
        'value': 'SP-LEDDNG',
      },
      {
        'id': 'G',
        'values': [
          'https://www.liteline.com/20000414-master_sp-leddng/surface-mount/dine-led-surface-pendant',
        ],
      },
      {
        'id': 'AW',
        'value': 'DINE LED Surface Pendant Series',
      },
      {
        'id': 'F',
        'value': 'The Dine Series is a diverse fixture that creates a cozy environment with uniform fixtures.',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://www.liteline.com/supplyimages/WF10007/SP-LEDDNG_specs.pdf',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED',
        ],
      },
      {
        'id': 'K',
        'value': '16W',
      },
      {
        'id': 'AD',
        'values': [
          '3000 k~2000 k',
        ],
      },
      {
        'id': 'M',
        'values': [
          '70% @ 50,000 hrs',
        ],
      },
      {
        'id': 'L',
        'value': 'Up to 900',
      },
      {
        'id': 'AJ',
        'value': '',
      },
      {
        'id': 'N',
        'values': [
          'Dimmable',
        ],
      },
      {
        'id': 'O',
        'values': [
          'Triac',
          'ELV / Electronic Low Voltage',
          'Phase Dim - Forward / Leading Edge',
          'Phase Dim - Reverse / Trailing Edge',
        ],
      },
      {
        'id': 'P',
        'values': [
          '100-135V AC, 60Hz',
        ],
      },
      {
        'id': 'AE',
        'value': '90+',
      },
      {
        'id': 'AA',
        'values': [
          '01HCCXYREZTM6CJYHY6FBJHS3F',
        ],
      },
      {
        'id': 'AB',
        'values': [
          'https://www.liteline.com/supplyimages/WF10007/SP-LEDDNG_specs.pdf',
        ],
      },
      {
        'id': 'V',
        'values': [
          'c.ETL.us listed',
        ],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'Track Head',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCCY32GG3P9VK6VD39GKH3VT',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCCY37KFV98P2EWRZR5MZF4V',
        ],
      },
      {
        'id': 'B',
        'value': 'Lumenpulse',
      },
      {
        'id': 'AO',
        'value': 'Spot M2',
      },
      {
        'id': 'G',
        'values': [
          'https://www.lumenpulse.com/products/431/spot-m2',
        ],
      },
      {
        'id': 'AW',
        'value': 'Small-format track head',
      },
      {
        'id': 'F',
        'value': 'The Spot M2 is a LED spotlight available in both 1-circuit and 2-circuit configurations. The Spot M2 offers a wide range of outputs',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://www.lumenpulse.com/files/view-file/supportfiles/8a91493946799180e838a0be1b0ad03c/2278/locale_file/lumenpulse-spot-m2-latsm2-specsheet-na-metric.pdf',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED',
        ],
      },
      {
        'id': 'K',
        'value': '7 W - 13 W',
      },
      {
        'id': 'AD',
        'values': [
          '2200',
          '2700K',
          '3000K',
          '3500K',
          '4000K',
        ],
      },
      {
        'id': 'M',
        'values': [
          '225,000 hrs',
        ],
      },
      {
        'id': 'L',
        'value': '700 lm, 1000 lm, 1300 lm, 2000 lm',
      },
      {
        'id': 'P',
        'values': [
          '120 volts',
        ],
      },
      {
        'id': 'AE',
        'value': 'CRI 80+, CRI 90+, CRI 95+',
      },
      {
        'id': 'V',
        'values': [
          'c.UL.us listed',
        ],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'Pendant',
          'Decorative',
          'Linear',
          'Task Light',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCCZN87GP8TS1STGZA2HBCX8',
          '01HCCZMN85Y7SP2J3R646EJ7NV',
          '01HCCZMN8959AZA42A8JCVRMVG',
          '01HCCZMNCBNQTRBRMPMPWM09PG',
          '01HCCZMN7RCECSR7JN0TDCYCK5',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCCZJHWAJNMA25QBX35F8026',
        ],
      },
      {
        'id': 'B',
        'value': 'Lumentruss',
      },
      {
        'id': 'AO',
        'value': 'Wood Silo',
      },
      {
        'id': 'G',
        'values': [
          'https://www.lumentruss.com/products/?wood/Silo',
        ],
      },
      {
        'id': 'AW',
        'value': 'Wood Silo Suspended',
      },
      {
        'id': 'F',
        'value': 'Luminaire of Parallel-strand lumber (PSL) with a varnished or natural finish that can be used as a decorative task light with an uplight option.',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://www.lumentruss.com/wp-content/uploads/lumentruss_files/LUMINERGIE/Lumentruss/LT_ORDERING_SHEET/LT_Silo_OS.pdf',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED tape/strip',
        ],
      },
      {
        'id': 'AD',
        'values': [
          'Variable',
        ],
      },
      {
        'id': 'M',
        'values': [
          '50000hrs',
        ],
      },
      {
        'id': 'N',
        'values': [
          'Remote',
          'In Canopy',
        ],
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'T-bar',
          'Ceiling',
          'Luminous Panel',
          'Troffer',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCD09ZRNHX5XB257BQHWM9FV',
          '01HCD09ZQSJ8XEYTVF11ENB2XQ',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCD0A82Q0YWPB71WWQ3CC8SW',
          '01HCD0A83BM9CR8B46K4ASRS03',
        ],
      },
      {
        'id': 'B',
        'value': 'Liteline',
      },
      {
        'id': 'AO',
        'value': 'EDGE LED low-Glare/ UGR panel light system',
      },
      {
        'id': 'G',
        'values': [
          'https://www.liteline.com/20000430-master_edge/edge/edge',
        ],
      },
      {
        'id': 'AW',
        'value': 'Edge Panel system for T-Bar and solid ceilings ( indicate size )',
      },
      {
        'id': 'F',
        'value': 'The Edge has changed the shape of standard panel lighting and is not all aesthetics. Imagine, high lumen output and more flexibility without the footprint. Available in',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://www.liteline.com/supplyimages/WF10007/EDGE_specs.pdf',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED',
        ],
      },
      {
        'id': 'BC',
        'value': '',
      },
      {
        'id': 'K',
        'value': '20W, 30W, 40W, 50W',
      },
      {
        'id': 'AD',
        'values': [
          '3000K',
          '3500K',
          '4000K',
          'Tunable White',
        ],
      },
      {
        'id': 'L',
        'value': '2100-5700 Lumens',
      },
      {
        'id': 'N',
        'values': [
          'Integral',
          '10% Level',
          'Dimmable',
        ],
      },
      {
        'id': 'O',
        'values': [
          '0-10V',
        ],
      },
      {
        'id': 'P',
        'values': [
          '120-227V',
          '347 volts',
        ],
      },
      {
        'id': 'AE',
        'value': '80+',
      },
      {
        'id': 'AB',
        'values': [
          'https://www.liteline.com/supplyimages/WF10007/EDGE-22-WH_instructions.pdf',
        ],
      },
      {
        'id': 'Q',
        'values': [
          'Direct wiring - integrated junction box',
        ],
      },
      {
        'id': 'U',
        'value': 'Damp location',
      },
      {
        'id': 'V',
        'values': [
          'c.ETL.us listed',
        ],
      },
      {
        'id': 'Y',
        'value': 'Canada',
      },
    ],
  },
  {
    collection: [
      {
        'id': 'C',
        'values': [
          'Wall Grazer',
          'Cove Light',
        ],
      },
      {
        'id': 'D',
        'values': [
          '01HCD0RP03AXPQHF5HVQYAMR2X',
        ],
      },
      {
        'id': 'E',
        'values': [
          '01HCD0RT1AN52C2YBJ7VY4H3W6',
        ],
      },
      {
        'id': 'B',
        'value': 'Insight',
      },
      {
        'id': 'AO',
        'value': 'Pilot PGM White Light',
      },
      {
        'id': 'G',
        'values': [
          'https://insightlighting.com/products/pgm-wl/',
        ],
      },
      {
        'id': 'AW',
        'value': 'A low-profile cove and wall grazer for interior applications.',
      },
      {
        'id': 'F',
        'value': 'A low-profile cove and wall grazer for interior applications.i',
      },
      {
        'id': 'S',
        'values': [
          'Interior',
        ],
      },
      {
        'id': 'Z',
        'values': [
          'https://insightlighting.com/download/pgm-white-light-specification-sheets/',
        ],
      },
      {
        'id': 'J',
        'values': [
          'Integrated LED',
        ],
      },
      {
        'id': 'K',
        'value': '4 W/FT, 7 W/FT,10 W/FT',
      },
      {
        'id': 'AD',
        'values': [
          '2700K',
          '3000K',
          '3500K',
          '4000K',
          'Dim-to-Warm',
        ],
      },
      {
        'id': 'M',
        'values': [
          '75000hrs',
        ],
      },
      {
        'id': 'L',
        'value': 'Up to 3576 Lumens',
      },
      {
        'id': 'AJ',
        'value': '10°X10°,10°X30°, 10°X60°, 15°X15°,15°X30°, 15°X60°, 30°X30°, 30°X60°, 60°X60°, Asymmetric',
      },
      {
        'id': 'N',
        'values': [
          'LED Driver',
          'Integral',
          'Dimmable',
          '1% Level',
        ],
      },
      {
        'id': 'P',
        'values': [
          '120-227V',
        ],
      },
      {
        'id': 'AE',
        'value': '82+ (Standard), 90+ (Option)',
      },
      {
        'id': 'AK',
        'values': [
          'aluminum',
          'extruded aluminum',
        ],
      },
      {
        'id': 'R',
        'values': [
          'Surface',
        ],
      },
      {
        'id': 'H',
        'value': 'Metallic Gray',
      },
      {
        'id': 'AH',
        'value': 'Polycarbonate',
      },
      {
        'id': 'AR',
        'value': '12\', 48\'',
      },
      {
        'id': 'AS',
        'value': '2\'',
      },
      {
        'id': 'AN',
        'value': '2.1\'',
      },
      {
        'id': 'T',
        'value': 'Hight CRI (90+), Louver',
      },
      {
        'id': 'AB',
        'values': [
          'https://insightlighting.com/download/pgm-installation-instructions/',
        ],
      },
      {
        'id': 'U',
        'value': 'Damp location',
      },
      {
        'id': 'V',
        'values': [
          'ETL',
          'c.ETL.us listed',
        ],
      },
      {
        'id': 'Y',
        'value': 'USA',
      },
      {
        'id': 'AU',
        'values': [
          'New Construction',
          'Remodel',
          'Retrofit',
        ],
      },
      {
        'id': 'AZ',
        'value': 'PGM',
      },
    ],
  },
];
