<template>
  <v-autocomplete
    v-model="selectedRole"
    :items="groupedItems"
    placeholder="Project Team Role*"
    :error-messages="errorMessages"
    densy
    item-text="name"
    item-value="value" />
</template>
<script>
import { extractUserTeamRole } from '@/utils';
export default {
  name: 'AccountPersonalInfoSelectedRole',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    groupedItems() {
      const { items } = this;
      const mappedItems = items.map(value => {
        const { category, role: name } = extractUserTeamRole(value);
        return {
          name,
          value,
          category,
        };
      });
      const groupByCategory = this.lodash.groupBy(mappedItems, 'category');
      const categories = Object.keys(groupByCategory);
      const grouptedItems = categories.reduce((result, option) => {
        const header = {
          header: option,
        };
        const categoryArray = [header, ...groupByCategory[option]];
        return [...result, ...categoryArray];
      }, []);
      return grouptedItems;
    },
    selectedRole: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('update:selected', val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  ::v-deep .v-list-item {
    padding-left: 36px;
    &--active {
      padding-left: 31px;
    }
  }
  .v-text-field {
    ::v-deep &__details {
      padding: 0 12px;
    }
    height: 56px;
    ::v-deep.v-input__slot {
      margin-bottom: 4px;
    }
    ::v-deep .v-select__slot {
      padding: 0 12px;
      input {
        font-size: 16px !important;
      }
    }
  }
  ::v-deep .v-subheader {
    height: 24px;
    margin-top: 18px;
    font-weight: 700;
    font-size: 16px;
    color: var(--v-lightBlack-base) !important;
  }
</style>
