<template>
  <div class="mr-3">
    <h2>
      Procore
    </h2>
    <div>
      <v-btn
        color="blue"
        class="mt-12 white--text elevation-0"
        @click="getProcoreAuthLink">
        Connect to Procore
      </v-btn>
    </div>
    <v-row v-if="procoreAccountsList.length > 0">
      <v-col>
        Your account is connected to Procore via user:
        {{ procoreAccountsList[0].login }}
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-btn
          color="red"
          class="white--text elevation-0"
          @click="disconnectProcoreAccount">
          Disconnect
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProcoreApi from '@/services/graphql/procore';
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
export default {
  name: 'AccountConnections',
  computed: {
    ...mapState('Procore', ['procoreAccountsList']),
  },
  async created() {
    this.spinner(true);
    await this.getProcoreAccountsList();
    this.spinner(false);
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions('Procore', ['getProcoreAccountsList']),
    async getProcoreAuthLink() {
      this.spinner(true);
      let response = await ProcoreApi.getProcoreAuthLink();
      this.spinner(false);
      window.location.href = response.data.response;
    },
    async disconnectProcoreAccount() {
      this.spinner(true);
      await ProcoreApi.disconnectProcorAccount({
        accountId: this.procoreAccountsList[0].login,
      });
      await this.getProcoreAccountsList();
      this.spinner(false);
    },
  },
};
</script>
