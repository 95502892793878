<template>
  <div>
    <p
      v-if="!isCompletedProfile"
      class="text-info red--text">
      Please complete your user profile: <em>{{ welcomeRequiredFieldsToRendering }}</em>.
    </p>
    <div class="user-info-wrapper">
      <div class="main-info">
        <div class="pesonal-info block d-flex flex-column justify-space-between">
          <h2>
            Personal information
          </h2>
          <div
            class="info-block"
            style="height: 100%">
            <div v-if="skeletonLoader.isLoadingAvatar">
              <v-row
                class="justify-center align-self-start avatar-skeleton__wrapper">
                <v-skeleton-loader
                  class="mt-4 mb-2"
                  height="100px"
                  type="avatar" />
              </v-row>
              <v-skeleton-loader
                class="mb-10"
                type="list-item" />
            </div>
            <v-row
              v-else
              justify="center"
              style="position: relative"
              no-gutters>
              <div class="image">
                <DragAndDropArea
                  :file="fileForPreview"
                  :is-avatar="true"
                  @uploadFile="uploadAvatar" />
                <file-upload
                  ref="upload"
                  v-model="files"
                  :multiple="false"
                  :drop="false"
                  :drop-directory="false"
                  @input-filter="inputFilter" />
                <div
                  class="badge-cirle-container">
                  <v-icon
                    v-if="userAvatar"
                    x-small
                    class="badge-cirle-container__close-icon"
                    @click="deleteAvatar">
                    mdi-close-circle
                  </v-icon>
                  <AvatarImage
                    error-action="getUserAvatar"
                    :avatar-key="userAvatarKey"
                    :avatar="userAvatar" />
                  <label
                    for="file"
                    class="small-p">
                    <div
                      class="circle-button-container circle-button-container__add-avatar">
                      <v-icon color="blue">
                        mdi-plus-circle-outline
                      </v-icon>
                    </div>
                  </label>
                </div>
              </div>
              <p class="small-p d-flex align-center text-center text-info mt-3">
                A photo helps personalize your account
              </p>
            </v-row>
            <template v-if="skeletonLoader.isLoadingUserProfile">
              <div
                class="text-center">
                <v-skeleton-loader
                  class="mb-16"
                  type="list-item-three-line" />
              </div>
              <v-row class="justify-center">
                <v-skeleton-loader
                  type="button" />
              </v-row>
            </template>
            <v-form
              v-else
              ref="updatePersonalInfo"
              @submit.prevent="updatePersonalInfo">
              <v-text-field
                v-model="personalInfo.name"
                :error-messages="nameErrors"
                autocomplete="name"
                data-test="user_name_input_field"
                placeholder="Name*"
                dense
                single-line
                @keydown="identityChanged = true" />
              <v-text-field
                v-model="personalInfo.company"
                :error-messages="companyErrors"
                autocomplete="organization"
                data-test="user_company_name_input_field"
                placeholder="Company*"
                dense
                single-line
                @keydown="identityChanged = true" />
              <SelectedRole
                :error-messages="projectTeamRolesErrors"
                :items="projectTeamRoles"
                :selected.sync="personalInfo.projectTeamRole"
                class="mt-0" />
              <v-text-field
                v-model="personalInfo.jobTitle"
                autocomplete="organization-title"
                data-test="user_job_title_input_field"
                placeholder="Job title"
                dense
                hide-details
                single-line />
              <location-field
                :current-location="personalInfo.location"
                :error-messages="locationErrors"
                placeholder="Location*"
                dense
                single-line
                use-location-logic
                @setLocationData="personalInfo.location = $event" />
              <v-btn
                class="white--text"
                color="blue"
                data-test="update_user_info_button"
                elevation="0"
                type="submit">
                Update
              </v-btn>
            </v-form>
          </div>
        </div>
        <!-- commenting out the code below -->
        <!-- TODO: figure out where it is used -->
        <!-- <v-col
          class="block-with-images"
          cols="12"
          sm="12"
          md="6">
          <div class="logo-wrapper block">
            <h2>
              Logo
            </h2>
            <div
              v-if="!skeletonLoader.isLoadingLogo"
              class="info-block pb-8">
              <v-row
                justify="center"
                style="position: relative"
                no-gutters>
                <div>
                  <DragAndDropArea
                    :file="logoForPreview"
                    :is-avatar="true"
                    :upload-logo-file="true"
                    @uploadFile="uploadLogo" />
                  <file-upload
                    ref="uploadLogo"
                    v-model="logos"
                    :multiple="false"
                    :drop="false"
                    :drop-directory="false"
                    input-id="logo"
                    @input-filter="inputFilter" />
                  <div
                    class="badge-cirle-container">
                    <template v-if="ownLogo">
                      <v-icon
                        x-small
                        class="badge-cirle-container__close-icon"
                        @click="deleteLogo">
                        mdi-close-circle
                      </v-icon>
                      <div
                        class="creator-logo-wrapper">
                        <img
                          style="max-width: 150px; max-height: 60px;"
                          :src="ownLogo">
                      </div>
                    </template>
                    <v-icon
                      v-else
                      size="98"
                      color="#67BED3">
                      mdi-image
                    </v-icon>
                    <label
                      for="logo"
                      class="small-p">
                      <div
                        class="circle-button-container circle-button-container__add-avatar">
                        <v-icon color="blue">
                          mdi-plus-circle-outline
                        </v-icon>
                      </div>
                    </label>
                  </div>
                </div>
                <div>
                  <p
                    v-if="!ownLogo"
                    class="small-p  text-center text-info">
                    Upload logo
                  </p>
                  <p class="text-center text-info mb-0">
                    Your logo is used for project documentation exporting
                  </p>
                </div>
              </v-row>
            </div>
            <div
              v-else
              class="info-block">
              <v-row class="justify-center align-self-start avatar-skeleton__wrapper">
                <v-skeleton-loader
                  width="140"
                  height="60"
                  class="mt-5"
                  type="image" />
              </v-row>
              <div class="d-flex justify-center">
                <v-col
                  cols="12"
                  md="7">
                  <v-skeleton-loader
                    type="list-item" />
                </v-col>
              </div>
            </div>
          </div>
        </v-col> -->
      </div>
      <div
        class="history block">
        <h2>
          Subscription history
        </h2>
        <AccountSubscriptionTable v-if="!skeletonLoader.isLoadingSubscription" />
        <ScheduleSkeleton
          v-else
          :header-columns="3"
          class="mt-4 px-4" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  Auth, Storage,
} from 'aws-amplify';
import { required } from 'vuelidate/lib/validators';
import FileUpload from 'vue-upload-component';
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { v4 as uuid } from 'uuid';
import DragAndDropArea from '@/components/App/AppDragAndDropArea';
import PaymentsApi from '@/services/graphql/payments';
import AccountSubscriptionTable from '@/components/Account/AccountSubscriptionTable';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
import { cacheControl } from '@/constants/cores';
import fileAttempts from '@/mixins/fileAttempts';
import ErrorsText from '@/constants/errors';
import AppGoogleLocationField from '@/components/App/AppFields/AppGoogleLocationField';
import SelectedRole from './AccountPersonalInfoSelectedRole';
import ScheduleCells from '@/services/graphql/scheduleCells';
import {
  isUserProfileComplete,
  missingUserProfileFields,
} from '@/utils';
export default {
  name: 'AccountPersonalInfo',
  components: {
    FileUpload,
    DragAndDropArea,
    AccountSubscriptionTable,
    ScheduleSkeleton,
    locationField: AppGoogleLocationField,
    SelectedRole,
  },
  mixins: [fileAttempts],
  data: () => ({
    files: [],
    logos: [],
    logoForPreview: null,
    fileForPreview: null,
    identityChanged: false,
    userSubscription: null,
    personalInfo: {
      name: '',
      company: '',
      jobTitle: '',
      picture: '',
      location: null,
      projectTeamRole: null,
    },
    skeletonLoader: {
      isLoadingSubscription: false,
      isLoadingUserProfile: false,
      isLoadingLogo: false,
      isLoadingAvatar: false,
      isLoadingSubscriptionInfo: false,
    },
    projectTeamRoles: [],
  }),
  validations: {
    personalInfo: {
      name: {
        required,
      },
      company: {
        required,
      },
      location: {
        required,
      },
      projectTeamRole: {
        required,
      },
    },
  },
  computed: {
    ...mapState('UserProfile', ['ownLogo', 'userData', 'userAvatar', 'userAvatarKey']),
    ...mapGetters('FeatureFlags', [
      'useSkeleton',
      'createNewCollectionProducts',
    ]),
    ...mapGetters('ManageFiles', ['getFullFileByUrl']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('UserProfile', ['isUserSignUp']),
    isCompletedProfile() {
      return isUserProfileComplete(this.userData);
    },
    welcomeRequiredFieldsToRendering() {
      return missingUserProfileFields(this.userData).map((field) => field).join(', ');
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.personalInfo.name.$dirty) return errors;
      if (!this.$v.personalInfo.name.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.personalInfo.company.$dirty) return errors;
      if (!this.$v.personalInfo.company.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    locationErrors() {
      const errors = [];
      if (!this.$v.personalInfo.location.$dirty) return errors;
      if (!this.$v.personalInfo.location.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    projectTeamRolesErrors() {
      const errors = [];
      if (!this.$v.personalInfo.projectTeamRole.$dirty) return errors;
      if (!this.$v.personalInfo.projectTeamRole.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    planPrice() {
      const { $formatCurrency } = this;
      const price = $formatCurrency(this.userSubscription.priceAmount / 100);
      let title = `${price} / ${this.userSubscription.priceInterval}`;
      if (this.userSubscription.priceInterval === 'year') {
        const pricePerMonth = $formatCurrency((+this.userSubscription.priceAmount / 12) / 100);
        title += ` (${pricePerMonth} / month)`;
      }
      return title;
    },
  },
  watch: {
    files(val) {
      const { file } = val[val.length - 1];
      this.fileForPreview = file;
    },
    logos(val) {
      const { file } = val[val.length - 1];
      this.logoForPreview = file;
    },
    userData: {
      handler() {
        for (const key in this.skeletonLoader) {
          if (key !== 'isLoadingSubscription') {
            this.skeletonLoader[key] = false;
          }
        }
      },
      immediate: true,
    },
    useSkeleton: {
      handler(val) {
        for (const key in this.skeletonLoader) {
          this.skeletonLoader[key] = val;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getUserSubscription();
    this.getPersonalInfo();
    this.getProjectTeamsRoles();
    this.$v.personalInfo.$touch();
  },
  methods: {
    ...mapActions({
      updateUserProfile: 'UserProfile/updateUserProfile',
      getOwnLogo: 'UserProfile/getOwnLogo',
      handleError: 'handleError',
      removeFilesFromCache: 'ManageFiles/removeFilesFromCache',
    }),
    ...mapMutations(['spinner', 'setUserInfo']),
    async getProjectTeamsRoles() {
      try {
        const { data } = await ScheduleCells.getDropdownValues({
          col: 'Project Team Role',
        });
        const { fixedValues: roles } = data.response;
        this.projectTeamRoles = roles;
      } catch (err) {
        console.log('getProjectTeamsRoles err');
      }
    },
    async deleteAvatar() {
      await this.updateUserProfile({
        attributes: {
          picture: null,
        },
      });
    },
    async deleteLogo() {
      await this.updateUserProfile({
        attributes: {
          logo: null,
        },
      });
    },
    async uploadAvatar({ documentId: picture }) {
      await this.updateUserProfile({
        attributes: {
          picture,
        },
      });
      this.fileForPreview = null;
    },
    uploadLogo(file) {
      this.saveLogo(file);
      this.logoForPreview = null;
    },
    inputFilter(newFile, oldFile, prevent) {
      let val;
      if (newFile && !oldFile) {
        if (newFile.file && !newFile.file.type.includes('image')) {
          val = prevent();
        }
      }
      return val;
    },
    getPersonalInfo() {
      const { userData } = this;
      const { company, name, job_title: jobTitle, picture, location, projectTeamRole } = userData || {
      };
      this.personalInfo = {
        company,
        name,
        jobTitle,
        picture,
        location,
        projectTeamRole,
      };
    },
    async updatePersonalInfo() {
      if (!this.$v.personalInfo.$invalid) {
        try {
          const { jobTitle: job_title, name, company, ...rest } = this.personalInfo;
          const attributes = {
            ...rest,
            name,
            company,
            job_title,
          };
          delete attributes.picture;

          // update cognito user info
          if (this.identityChanged) {
            const newVal = {
              'name': name,
              'custom:company': company,
            };
            const user = await Auth.currentAuthenticatedUser();
            await Promise.all([
              Auth.updateUserAttributes(user, newVal),
              this.updateUserProfile({
                attributes,
              }),
            ]);

            this.identityChanged = false;
          } else {
            await this.updateUserProfile({
              attributes,
            });
          }
        } catch (err) {
          this.handleError(err);
          if (err === 'not authenticated') {
            this.$router.push({
              name: 'login',
            });
          }
        }
      }
    },
    /**
     * @todo remove if we really will use nickname only
     * @param nick
     * @returns {*}
     */
    getSubscriptionTitle(nick) {
      let title;
      if (nick.includes('Premium')) {
        title = 'Pro';
      }
      if (nick.includes('Design Partner')) {
        title = 'Collaborator';
      }
      return title;
    },
    async getUserSubscription() {
      try {
        if (this.useSkeleton) {
          this.skeletonLoader.isLoadingSubscription = true;
        }
        const { data: { getUserSubsriptions } } = await PaymentsApi.getUserSubscriptions();
        this.userSubscription = getUserSubsriptions && getUserSubsriptions.length
          ? getUserSubsriptions[getUserSubsriptions.length - 1]
          : null;
      } catch (err) {
        console.log('err', err);
      } finally {
        if (this.useSkeleton) {
          this.skeletonLoader.isLoadingSubscription = false;
        } else {
          this.spinner(false);
        }
      }
    },
    async saveLogo(file) {
      this.spinner(true);
      try {
        const image = file;
        const id = `${uuid()}/${image.name}`;
        const metadata = {
          workspace_id: this.activeWorkspaceId,
        };
        const { key } = await Storage.put(id, image, {
          contentType: image.type,
          level: 'public',
          metadata,
          cacheControl,
        }) ?? {
        };
        key && await this.updateUserProfile({
          attributes: {
            logo: key,
          },
        });
      } catch (err) {
        this.handleError(err);
      }
    },
  },
};
</script>
<style scoped lang="scss" src="./index.scss"></style>
