<template>
  <v-row class="accounts-page">
    <v-col
      md="4"
      sm="12"
      cols="12"
      class="nav-card nav-card__account-menu">
      <router-link
        v-for="item in items"
        :key="item.link"
        :data-test="item.dataTest"
        :to="{ path: `/account/${item.link}` }"
        class="mt-auto mb-auto">
        <p
          class="small-p">
          <span>
            <v-icon
              color="lightBlack"
              style="font-size: 16px"
              class="mr-2">
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </span>
        </p>
      </router-link>
    </v-col>
    <v-col
      md="8"
      sm="12"
      cols="12"
      class="">
      <h3 class="accounts-page__title">
        My Account
      </h3>
      <component
        :is="$route.params.field"
        v-if="checkArr.includes($route.params.field)" />
    </v-col>
  </v-row>
</template>
<script>
import Security from '@/components/Account/AccountSecurity';
import PersonalInfo from '@/components/Account/AccountPersonalInfo';
import AccountConnections from '@/components/Account/AccountConnections';
import ProjectsApi from '@/services/graphql/projects';
import CollectionsApi from '@/services/graphql/collections';
import CollectionProductsApi from '@/services/graphql/collectionProducts';
import CollectionData from '@/dataFront/dummy-collection-data';
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
export default {
  name: 'Account',
  components: {
    security: Security,
    'personal-info': PersonalInfo,
    'connections': AccountConnections,
  },
  data: () => ({
    checkArr: [],
    items: [
      {
        title: 'Personal info', icon: 'mdi-account-box-outline', link: 'personal-info', dataTest: 'personal_info_tab_button',
      },
      {
        title: 'Security', icon: 'mdi-lock-outline', link: 'security', dataTest: 'security_tab_button',
      },
      {
        title: 'Connections', icon: 'mdi-connection', link: 'connections', dataTest: 'connections_tab_button',
      },
    ],
  }),
  computed: {
    ...mapGetters('FeatureFlags', ['useProcoreConnection']),
    ...mapGetters('UserProfile', ['isUserSignUp']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', [
      'isToggleToMoveToVerifiedArea',
      'createNewCollectionProducts',
    ]),
  },
  async created() {
    this.scaffoldProject();
    if (!this.useProcoreConnection) {
      this.items = this.items.filter(item => item.link !== 'connections');
    }
    this.checkArr = this.items.map(item => item.link);
    if (!this.checkArr.includes(this.$route.params.field)) {
      this.$router.push({
        path: '/account',
      });
    }
  },
  methods: {
    ...mapMutations({
      isSignUp: 'UserProfile/setUserSignUp',
      spinner: 'spinner',
    }),
    async scaffoldProject() {
      if (!this.createNewCollectionProducts) return;
      try {
        if (this.isUserSignUp) {
          this.spinner(true);
          const deprecatedCreateProject = 'deprecatedCreateProjectV2';
          await ProjectsApi[deprecatedCreateProject]({
            projectName: 'Project First',
            phase: '',
            number: '',
            location: '',
            baseBudget: 0,
            area: 0,
            powerDensity: 0,
            areaUnit: 'feet',
            workspaceId: this.activeWorkspaceId,
          });
          const create = this.isToggleToMoveToVerifiedArea ? 'createV3' : 'createV2';
          const { data } = await CollectionsApi[create]({
            name: 'First Collection',
            workspaceId: this.activeWorkspaceId,
            libraryId: this.activeWorkspaceId,
          });

          const createProductPromises = CollectionData.map(async (collection) => {
            await CollectionProductsApi.createProduct({
              product: collection.collection,
              workspaceId: this.activeWorkspaceId,
              collectionId: data.response.id,
              ...this.isSaveAsNewVariant && {
                fromProject: true,
              },
            });
          });

          await Promise.all(createProductPromises);

          this.spinner(false);
          await this.isSignUp(false);
        }
      } catch (error) {
        console.log('error', error);
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .accounts-page {
    .nav-card__account-menu {
      width: 250px;
      max-width: 250px;
      max-height: 90px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-height: 100%;
        margin-right: auto;
        margin-bottom: 24px;
      }
    }
    margin: 80px 0 80px 70px;
    max-width: 1500px;
    justify-content: space-around;
    @media only screen and (max-width: 599px) {
      margin: 20px;
    }
    &__title {
      font-size: 24px;
    }
    .nav-card {
      p {
        margin-bottom: 0;
        height: 44px;
        padding-left: 20%;
        span {
          width: 100%;
        }
      }
    }
  }
</style>
