<template>
  <div class="mr-3">
    <h2 class="mb-3">
      Change your password
    </h2>
    <v-form
      ref="updatePassword"
      @submit.prevent="updatePassword">
      <v-text-field
        v-model="password.currentPassword"
        placeholder="Current password"
        single-line
        type="password"
        dense
        data-test="current_password_input_field"
        :error-messages="currentPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!currentPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @input="errorWithCode = null" />
      <v-text-field
        v-model="password.newPassword"
        placeholder="New Password"
        single-line
        :type="showNewPassword ? 'text' : 'password'"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        dense
        data-test="new_password_input_field"
        :error-messages="newPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!newPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showNewPassword = !showNewPassword" />
      <v-text-field
        v-model="password.repeatPassword"
        placeholder="Confirm password"
        single-line
        :type="showConfirmPassword ? 'text' : 'password'"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        dense
        data-test="confirm_password_input_field"
        :error-messages="repeatPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!repeatPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showConfirmPassword = !showConfirmPassword" />
      <v-row class="d-flex justify-center mt-12">
        <v-btn
          type="submit"
          data-test="update_password_info_button"
          color="blue"
          class="white--text elevation-0">
          Update
        </v-btn>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import {
  required,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators';
import { Auth } from 'aws-amplify';
import { mapMutations } from 'vuex';
import ErrorsText from '@/constants/errors';
export default {
  name: 'AccountSecurity',
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    errorWithCode: null,
    showNewPassword: false,
    showConfirmPassword: false,
    password: {
      currentPassword: null,
      newPassword: null,
      repeatPassword: null,
    },
  }),
  validations: {
    password: {
      currentPassword: {
        required, minLength: minLength(8),
      },
      newPassword: {
        required, minLength: minLength(8),
      },
      repeatPassword: {
        required, sameAs: sameAs('newPassword'),
      },
    },
  },
  computed: {
    currentPasswordErrors() {
      const errors = [];
      if (!this.$v.password.currentPassword.$dirty) return errors;
      if (!this.$v.password.currentPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.currentPassword.minLength) {
        errors.push('Password must be with 8 characters long');
      }
      if (this.errorWithCode) {
        this.errorWithCode == ErrorsText.ERROR_WITH_USERNAME_OR_PASSWORD ? errors.push(ErrorsText.ERROR_WITH_PASSWORD) : errors.push(this.errorWithCode);
      }
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.password.newPassword.$dirty) return errors;
      if (!this.$v.password.newPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.newPassword.minLength) {
        errors.push('Password must be with 8 characters long');
      }
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.password.repeatPassword.$dirty) return errors;
      if (!this.$v.password.repeatPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.repeatPassword.sameAs) {
        errors.push('Confirm Password must be the same');
      }
      return errors;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    async updatePassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        this.spinner(true);
        try {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(user, this.password.currentPassword, this.password.newPassword);
          this.$refs.updatePassword.reset();
          this.$v.password.$reset();
        } catch (err) {
          if (err === 'not authenticated') {
            this.$router.push({
              name: 'login',
            });
          } else {
            this.errorWithCode = err.message;
          }
        } finally {
          this.spinner(false);
        }
      }
    },
  },
};
</script>
