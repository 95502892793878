<template>
  <div class="history-table table-container table-container__shadowed">
    <v-data-table
      hide-default-footer
      item-key="intId"
      :headers="headers"
      :mobile-breakpoint="0"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :items="invoices"
      @page-count="pageCount = $event">
      <template #[`item.keyToPdf`]="{ item }">
        <v-icon @click="downloadInvoice(item.keyToPdf)">
          mdi-download
        </v-icon>
      </template>
      <template #[`item.description`]="{ item }">
        {{ setType(item.data) }}
      </template>
      <template #[`item.period`]="{ item }">
        {{ setPeriod(item.data) }}
      </template>
    </v-data-table>
    <div class="d-flex align-center">
      <v-pagination
        v-model="page"
        :length="pageCount"
        class="py-2 grow"
        color="blue" />
      <div class="d-flex gap-4">
        <v-btn
          class="white--text"
          color="blue"
          elevation="0"
          @click="cancelPayments">
          Cancel
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentsApi from '@/services/graphql/payments';
import { HELP_CENTER } from '@/constants/urls';
import {
  mapState, mapActions, mapMutations,
} from 'vuex';
export default {
  name: 'AccountSubscriptionTable',
  data: () => ({
    headers: [
      {
        text: 'Subscription type', value: 'description', align: 'left',
        sortable: false,
      },
      {
        text: 'Period', value: 'period', align: 'left',
        sortable: false,
      },
      {
        text: 'Download', value: 'keyToPdf', align: 'right',
        sortable: false,
      },
    ],
    invoices: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
  }),
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
  },
  created() {
    this.getUserInvoices();
  },
  methods: {
    ...mapActions(['getFileFromS3']),
    ...mapMutations(['spinner']),
    async getUserInvoices() {
      try {
        const response = await PaymentsApi.getUserInvoices();
        this.invoices = response.data.response;
      } catch (err) {
        console.log(err);
      }
    },
    async downloadInvoice(key) {
      this.spinner(true);
      await this.getFileFromS3({
        level: 'protected',
        identityId: 'invoices',
        key,
        forceUpdate: true,
      });
      this.spinner(false);
    },
    setType(data) {
      return data[data.length - 1]?.description;
    },
    setPeriod(data) {
      let formatFromUnixFormat = (val) => this.$moment.unix(val).format('DD MMMM YYYY');
      return `${formatFromUnixFormat(data[0]?.start)} - ${formatFromUnixFormat(data[0]?.end)}`;
    },
    cancelPayments() {
      window.open(HELP_CENTER, '_blank');
    },
  },
};
</script>
